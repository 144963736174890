.play-video {
  &::before {
    content: '';
    background-image: url('../images/play.svg');
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -35px;
    margin-top: -35px;
    z-index: 10;
  }
}