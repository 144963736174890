$card-spacer-x: 1.25rem !default;
$card-spacer-y: .75rem !default;
$card-border-width: 2px;
$card-border-radius: 0;
$card-border-color: $body-color;
$card-border-radius-inner: 0;
$card-cap-bg: $gray-lightest !default;
$card-bg: transparent;

$card-link-hover-color: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin: ($grid-gutter-width-base / 2) !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;