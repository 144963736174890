@import "bootstrap/scss/variables";
@import "colors";

@import "buttons";
@import "cards";
@import "custom-forms";
@import "dropdown";
@import "fonts";
@import "fontsizes";
@import "forms";
@import "input-group";
@import "links";
@import "modal";
@import "list-group";
@import "table";

// Colors
$road-tube-darkblue: #0f1137;
$road-tube-lightgreen: #5f9577;
$road-tube-yellow: #fb9e1f;
$road-tube-darkcyan: #3e6f7a;
$road-tube-orange: #e14e12;

// Global options
$enable-flex: true;