@import "bootstrap/scss/forms";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/input-group";

@import "../vendor/flatpickr";

.form-control {
  @include font-roboto;
  border: 0;
}

label {
  @include font-roboto;
  font-weight: bold;
}

.form-block {
  background-color: $white;
  margin-bottom: 10px;
  padding: 30px;

  @include media-breakpoint-up(md) {
    padding: 40px 100px;
  }

  legend {
    transform: translateY(100%);
    @include type-h3;
  }

  a[data-toggle="modal"]::after {
    content: 'i';
    font-family: sans-serif;
    font-size: 12px;
    line-height: 1.2;
    background-color: $yellow;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 5px;
    border-radius: 50%;
    color: $body-color;
    text-align: center;
  }
}

.custom-radio,
.custom-checkbox {
  @include font-roboto;
  background-color: rgba($beige, .7);
  transition: background-color ease .28s;

  &:hover {
    background-color: $antique-white;
  }
}

.custom-control-indicator {
  top: 1.25rem;
  left: 1.25rem;
  border: 1px solid rgba($body-color, .2);
}

.custom-control {
  font-weight: normal;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  &.is-active {
    font-weight: bold;
    background-color: $jonquil;
  }
}
.custom-checkbox {
  border: 1px dashed #ccc;
}

.custom-controls-stacked .custom-control {
  margin-right: 0;
}

.custom-control-description {
  display: block;
  width: 100%;
  padding-right: 1rem;

  &__right {
    float: right;
  }
}

.custom-select {
  @include font-roboto;
  width: 100%;
  height: 3.75rem;
}

.input-group-addon {
  @include font-roboto;
  border: 0;
}

.input-group-btn,
.input-group-btn-vertical {
  .btn {
    border-radius: 0;
  }
}

.input-group-btn-vertical {
  @extend .input-group-btn;

  .btn {
    font-weight: normal;
    padding: 0 .75rem;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.custom-radio--image {
  strong {
    font-weight: normal;
    display: block;
    margin-bottom: .5rem;
  }

  &.is-active {
    font-weight: normal;

    strong {
      font-weight: bold;
    }
  }

  .custom-control-description {
    position: relative;
  }

  &__image {
    float: right;
    position: relative;
    max-width: 50%;
    margin-left: 1rem;

    img {
      @include img-fluid;
    }
  }
}

.flatpickr-input {
  background-image: url('../images/calendar-disabled.svg');
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
}

.flatpickr-calendar {
  @include font-roboto;
}