@import "copy";
@import "social";

.footnote {
  margin-top: 50px;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}