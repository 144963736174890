.social {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }

  a {
    margin-left: 5px;
    transition: opacity ease .28s;

    &:hover {
      opacity: .7;
    }
  }

  img {
    margin-bottom: 0 !important;
  }
}