@import "bootstrap/scss/type";

@mixin type-h1 {
  @include font-arsenal;
  font-size: 50px;
  font-weight: bold;
}

@mixin type-h2 {
  @include font-arsenal;
  font-size: 32px;
  font-weight: bold;
  line-height: 34px;
}

@mixin type-h3 {
  @include font-arsenal;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
}

@mixin type-h4 {
  @include font-arsenal;
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
}