.footer {
  background-color: $yellow;
  color: $body-color;
  padding: 65px 0;

  @include media-breakpoint-down(md) {
      padding-top: 0;
  }

  a {
    color: $body-color;
  }

  &__title {
    @include type-h3;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }

  &__copy {
    a {
      color: $body-color;
      text-decoration: underline;
    }
  }

  &__reviews {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__review {
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0 1rem;

    &-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 .5rem;
      width: 100%;
    }

    .stars {
      display: flex;
      align-items: center;
      width: 5rem;
    }

    .star {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGcgaWQ9ImluZm8iLz48ZyBpZD0iaWNvbnMiPjxwYXRoIGQ9Ik0xMi45LDIuNmwyLjMsNWMwLjEsMC4zLDAuNCwwLjUsMC43LDAuNmw1LjIsMC44QzIyLDksMjIuMywxMCwyMS43LDEwLjZsLTMuOCwzLjljLTAuMiwwLjItMC4zLDAuNi0wLjMsMC45ICAgbDAuOSw1LjRjMC4xLDAuOC0wLjcsMS41LTEuNCwxLjFsLTQuNy0yLjZjLTAuMy0wLjItMC42LTAuMi0wLjksMGwtNC43LDIuNmMtMC43LDAuNC0xLjYtMC4yLTEuNC0xLjFsMC45LTUuNCAgIGMwLjEtMC4zLTAuMS0wLjctMC4zLTAuOWwtMy44LTMuOUMxLjcsMTAsMiw5LDIuOCw4LjlsNS4yLTAuOGMwLjMsMCwwLjYtMC4zLDAuNy0wLjZsMi4zLTVDMTEuNSwxLjgsMTIuNSwxLjgsMTIuOSwyLjZ6IiBpZD0iZmF2b3JpdGUiLz48L2c+PC9zdmc+');
      background-size: 1rem;
      width: 100%;
      height: 1rem;
      margin-left: 0;
    }
  }
}