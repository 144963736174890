.content {
  &__index {
    background-color: $beige;
  }

  h2, .h2 {
    @include type-h2;
  }

  h3, .h3 {
    @include type-h3;
  }

  h4, .h4 {
    @include type-h4;
  }

  p, ul, ol, img {
    & + h2,
    & + h3 {
      margin-top: 2rem;
    }
  }

  img {
    @include img-fluid;
    width: 100%;
    margin-bottom: 1rem;
  }

  a {
    &[data-toggle="remote-modal"],
    &[data-toggle="modal"] {
      &::after {
        content: 'i';
        font-family: sans-serif;
        font-size: 12px;
        line-height: 1.2;
        background-color: $yellow;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 5px;
        border-radius: 50%;
        text-align: center;
      }
    }
  }

  .embeditem,
  .responsive-video {
    position: relative;
    padding-bottom: 56.25%;

    &.iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}