.trips {
  @include make-row;
  justify-content: center;

  &-holder {
    margin-bottom: 60px;
  }

  &__item {
    @include make-col-ready;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      @include make-col(6);
      max-width: 50%;
    }

    @include media-breakpoint-up(lg) {
      @include make-col(3);
      max-width: 35%;
    }

    .two-col &,
    .three-col & {
      flex: 0 0 100%;
    }

    @include media-breakpoint-up(lg) {
      .two-col & {
        flex: 0 0 50%;
      }

      .three-col & {
        flex: 0 0 33%;
      }
    }

    &__img-frame {
      background-color: #e2e2e2;
      margin-bottom: 20px;
      padding: 0 20px;
      text-align: center;
    }

    &__img {
      background-size: cover;
      background-position: center;
      display: block;
      height: 188px;
      transform: translateY(20px);
    }

    &__header {
      background-color: $white;
    }

    &__details {
      @include font-roboto;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
    }

    &__details,
    &__content,
    &__footer {
      background-color: $white;
      padding: 10px 20px;
    }

    &__content {
      flex-grow: 1;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 20px 20px;
    }
  }

  .price {
    @include font-roboto;
  }
}