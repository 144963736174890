@import "site-title";

.brand {
  display: flex;
  align-items: flex-start;

  .monogram {
    display: flex;
    align-self: center;
    margin-right: 1rem;
  }

  .site-title {
    align-self: center;
    flex: 1 1 0%;
  }
}