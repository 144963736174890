.page {
  @include make-row;
  position: relative;
  z-index: 20;

  &__content {
    @include make-col-ready;

    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
  }

  img {
    width: 100%;
  }

  .content {
    background-color: $white;
    margin-bottom: 10px;
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 40px 100px;
    }
  }

  .container:first-of-type &,
  .container:only-child & {
    transform: translateY(-60px);
  }
}