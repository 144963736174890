.accomodations {
  &__item {
    padding-bottom: 30px;

    &__title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .btn {
        @extend .btn-secondary;
        float: right;
        clear: both;

        &.collapsed {
          @extend .btn-primary;
        }
      }
    }

    &__content {
      clear: both;
    }
  }
}

.accomodation {
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;

    &__thumb {
      display: flex;
      margin-right: 1rem;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__body {
      flex: 1;

      > h4 {
        margin-bottom: 0;
      }
    }
  }
}