@import "slick-carousel/slick/slick.scss";

.slider {
  &--day-to-day {
    position: relative;
  }

  &__item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 140px 0;

    @include media-breakpoint-down(sm) {
      background-image: none !important;
      padding: 0;
    }

    &__image {
      &--mobile {
        display: none;
        margin-left: -15px;
        margin-right: -15px;

        @include media-breakpoint-down(sm) {
          display: block;
        }

        img {
          @include img-fluid;
        }
      }
    }

    &__content {
      background-color: $white;
      display: flex;
      align-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 30px;

      @include media-breakpoint-up(md) {
        min-height: 360px;
      }

      &__wrap {
        flex-grow: 1;
        overflow: auto;

        @include media-breakpoint-up(md) {
          height: 320px;
        }
      }

      @include media-breakpoint-down(sm) {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
      }

      a {
        color: $carrot-orange;
        text-decoration: underline;
      }

      h3 {
        font-weight: normal;

        em {
          font-style: normal;
          font-weight: bold;
        }
      }
    }

    &__controls {
      display: flex;
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: -30px;

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 0;
        left: 30px;
        right: 30px;
        margin-left: -15px;
        margin-right: -15px;
        transform: translateY(-100%);
      }

      a {
        @include font-roboto;
        font-size: 16px;
        font-weight: bold;
        background-color: #ccc;
        background-repeat: no-repeat;
        flex: 1;
        height: 40px;
        padding: 9px 20px;
        color: $body-color;
        text-decoration: none;

        &.prev {
          background-color: $old-lace;
          background-image: url('../images/slide-prev.svg');
          background-position: 5% 50%;
          padding-left: 35px;
          text-align: left;
        }

        &.next {
          background-color: $flame;
          background-image: url('../images/slide-next.svg');
          background-position: 95% 50%;
          padding-right: 35px;
          color: $white;
          text-align: right;
        }
      }
    }
  }

  &__controls {
    background-color: rgba($oxford-blue, .20);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 0 15px;

    &__line {
      background-color: $white;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      height: 1px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .slick-dots {
      display: flex;
      justify-content: space-between;
      list-style: none;
      position: relative;
      margin: 0;
      padding: 0;

      li {
        text-align: center;

        &.slick-active {
          &::before {
            background-color: $flame;
          }

          button {
            color: $flame;
          }
        }

        &:first-child {
          margin-left: -8px;
        }

        &:last-child {
          margin-right: -8px;
        }

        &::before {
          content: '';
          background-color: $white;
          display: block;
          position: relative;
          width: 7px;
          height: 7px;
          margin: -3px auto 0;
          border-radius: 50%;
          z-index: 2;
        }
      }

      button {
        background-color: transparent;
        color: $white;
        border: 0;
      }
    }
  }
}