.container--wide {
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;

  & .content-grid-row > div {
    @include media-breakpoint-up(md) {
      flex: 0 50%;
    }

    @include media-breakpoint-up(xl) {
      flex: 1;
    }
  }
}

.content-grid {
  &-cell {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 280px;
    max-height: 280px;
    margin-bottom: 0;
    border: 3px solid transparent;
    overflow: hidden;

    .bl__img {
      background-size: cover;
      background-repeat: no-repeat;
      display: block;
      width: 100%;
      height: 100%;
      transform: scale(1);
      transition: transform 0.3s;
    }

    &--heading {
      font-weight: bold;
      line-height: 20px;
      position: absolute;
      bottom: -16px;
      width: 100%;
      padding: 10px 15px;
      color: white;
      user-select: none;

      @include media-breakpoint-up(md) {
        bottom: 3px;
        width: 315px;
      }

      @include media-breakpoint-up(xl) {
        width: 90%;
      }
    }
  }

  .cell-heading {
    &--yellow {
      background-color: $road-tube-yellow;
    }

    &--orange {
      background-color: $road-tube-orange;
    }

    &--darkcyan {
      background-color: $road-tube-darkcyan;
    }

    &--lightgreen {
      background-color: $road-tube-lightgreen;
    }
  }

  &-row &-cell {
    padding-right: 0;
    padding-left: 0;
  }

  &-video-block {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    height: 100%;
    transform: scale(1);
    transition: transform 0.3s, opacity 0.3s;

    &:before {
      transition: opacity 0.3s;
    }
  }

  &-video-block:hover,
  &-video-block:focus,
  .bl__img:hover,
  .bl__img:focus {
    transform: scale(1.05);

    &:before {
      opacity: 0.8;
    }
  }
}