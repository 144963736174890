.road-tube {
  & > .usp {
    display: none;
  }

  main {
    margin-top: -60px;
  }

  .hero {
    height: 280px;
  }

  &-content {
    background-color: $road-tube-darkblue;
    padding-bottom: 150px;
  }

  &-heading {
    margin-top: 50px;

    &--image {
      width: 280px;
      height: auto;

      @include media-breakpoint-up(md) {
        width: 368px;
      }
    }
  }

  .play-video::before {
    background-image: url('../images/play-reversed.svg');
  }
}