.section {
  &__title {
    @include type-h2;
    margin-bottom: 30px;
    text-align: center;
  }

  &__intro {
    margin: 0 auto 30px;
    max-width: 700px;
    text-align: center;

    a {
      color: $body-color;
      text-decoration: underline;
    }
  }
}