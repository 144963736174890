.intro {
  background-color: $white;
  position: relative;
  z-index: 20;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &::before {
    content: '';
    background-color: $white;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -2000px;
    z-index: -1;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__text,
  &__img {
    @include media-breakpoint-up(md) {
      flex: 1;
    }
  }

  &__img {
    img {
      @include img-fluid;
    }

    &__block {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      position: relative;

      @include media-breakpoint-down(lg) {
        height: 260px;
      }

      @include media-breakpoint-up(md) {
        height: 100%;
      }
    }
  }

  &__text {
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 60px;
    }

    h2 {
      font-size: 32px;
      font-style: italic;
      font-weight: 400;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }

  &--usp {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    transform: translateY(-60px);

    @include media-breakpoint-down(md) {
      display: block;
    }

    &::before {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .intro__txt {
      padding: 30px;

      @include media-breakpoint-up(lg) {
        @include make-col(8);
        padding-left: 0;
      }
    }

    .intro__usps {
      background-color: $yellow;
      padding: 30px;

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      ul {
        @extend .usp;
        @extend .usp--list;
      }
    }
  }
}