.bl-row {
  @include media-breakpoint-up(md) {
    @include make-row;
  }

  .bl {
    @include make-col-ready;
    flex: 1;
  }

  &--1 {
    .bl {
      @include media-breakpoint-up(md) {
        @include make-col-offset(1);
        @include make-col(10)
      }
    }
  }

  &--2 {
    .bl {
      display: flex;

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      > * {
        flex: 1;
      }
    }
  }

  &--3 {
    .bl {
      display: flex;

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      > * {
        flex: 1;
      }
    }
  }

  &--4 {
    .bl {
      display: flex;

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(3);
      }

      > * {
        flex: 1;
      }
    }
  }
}