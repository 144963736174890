.page-banner {
  position: relative;
  height: 300px;
  z-index: 11;

  &-content {
    background-color: #f8d27b;
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  p {
    margin: 0;
  }
}