.site-title {
  @include font-roboto;
  font-size: 22px;
  font-weight: 300;
  color: $white;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }

  strong {
    font-weight: bold;

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  .blog-overview & {
    color: $oxford-blue;
  }
}