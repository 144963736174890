$fontpath: "../fonts/";

@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@400&display=swap');

// arsenal-regular - latin
@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontpath}arsenal-v1-latin-regular.eot');
  src: local('Arsenal Regular'), local('Arsenal-Regular'),
  url('#{$fontpath}arsenal-v1-latin-regular.eot?#iefix') format('embedded-opentype'),
  url('#{$fontpath}arsenal-v1-latin-regular.woff2') format('woff2'),
  url('#{$fontpath}arsenal-v1-latin-regular.woff') format('woff'),
  url('#{$fontpath}arsenal-v1-latin-regular.ttf') format('truetype'),
  url('#{$fontpath}arsenal-v1-latin-regular.svg#Arsenal') format('svg');
}

// arsenal-italic - latin
@font-face {
  font-family: 'Arsenal';
  font-style: italic;
  font-weight: 400;
  src: url('#{$fontpath}arsenal-v1-latin-italic.eot');
  src: local('Arsenal Italic'), local('Arsenal-Italic'),
  url('#{$fontpath}arsenal-v1-latin-italic.eot?#iefix') format('embedded-opentype'),
  url('#{$fontpath}arsenal-v1-latin-italic.woff2') format('woff2'),
  url('#{$fontpath}arsenal-v1-latin-italic.woff') format('woff'),
  url('#{$fontpath}arsenal-v1-latin-italic.ttf') format('truetype'),
  url('#{$fontpath}arsenal-v1-latin-italic.svg#Arsenal') format('svg');
}

// arsenal-700 - latin
@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fontpath}arsenal-v1-latin-700.eot');
  src: local('Arsenal Bold'), local('Arsenal-Bold'),
  url('#{$fontpath}arsenal-v1-latin-700.eot?#iefix') format('embedded-opentype'),
  url('#{$fontpath}arsenal-v1-latin-700.woff2') format('woff2'),
  url('#{$fontpath}arsenal-v1-latin-700.woff') format('woff'),
  url('#{$fontpath}arsenal-v1-latin-700.ttf') format('truetype'),
  url('#{$fontpath}arsenal-v1-latin-700.svg#Arsenal') format('svg');
}

// roboto-condensed-regular - latin
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fontpath}roboto-condensed-v13-latin-regular.eot');
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
  url('#{$fontpath}roboto-condensed-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
  url('#{$fontpath}roboto-condensed-v13-latin-regular.woff2') format('woff2'),
  url('#{$fontpath}roboto-condensed-v13-latin-regular.woff') format('woff'),
  url('#{$fontpath}roboto-condensed-v13-latin-regular.ttf') format('truetype'),
  url('#{$fontpath}roboto-condensed-v13-latin-regular.svg#RobotoCondensed') format('svg');
}

// roboto-condensed-light - latin
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('#{$fontpath}roboto-condensed-v13-latin-300.eot');
  src: local('Roboto Condensed'), local('RobotoCondensed-Light'),
  url('#{$fontpath}roboto-condensed-v13-latin-300.eot?#iefix') format('embedded-opentype'),
  url('#{$fontpath}roboto-condensed-v13-latin-300.woff2') format('woff2'),
  url('#{$fontpath}roboto-condensed-v13-latin-300.woff') format('woff'),
  url('#{$fontpath}roboto-condensed-v13-latin-300.ttf') format('truetype'),
  url('#{$fontpath}roboto-condensed-v13-latin-300.svg#RobotoCondensed') format('svg');
}

// roboto-condensed-700 - latin
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('#{$fontpath}roboto-condensed-v13-latin-700.eot');
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
  url('#{$fontpath}roboto-condensed-v13-latin-700.eot?#iefix') format('embedded-opentype'),
  url('#{$fontpath}roboto-condensed-v13-latin-700.woff2') format('woff2'),
  url('#{$fontpath}roboto-condensed-v13-latin-700.woff') format('woff'),
  url('#{$fontpath}roboto-condensed-v13-latin-700.ttf') format('truetype'),
  url('#{$fontpath}roboto-condensed-v13-latin-700.svg#RobotoCondensed') format('svg');
}