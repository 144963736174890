@import "bootstrap/scss/modal";
@import "bootstrap/scss/close";

.modal iframe {
  border: 0;
}

.modal-content {
  border-radius: 0;
}

.close {
  font-weight: 300;
  color: $oxford-blue;
  margin-top: -3px;
}

.modal-body {
  img {
    @include img-fluid;
  }
}