$btn-font-weight: 700;
$btn-border-radius: 100px;
$input-btn-border-width: 2px;

$btn-padding-x: 1.1875rem;
$btn-padding-y: .5rem;

$btn-primary-color: $white;
$btn-primary-bg: $red;
$btn-primary-border: $red;

$btn-secondary-color: $body-color;
$btn-secondary-bg: transparent;
$btn-secondary-border: $body-color;

$btn-info-color: $white;
$btn-info-bg: $body-color;
$btn-info-border: $body-color;