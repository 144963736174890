$custom-control-gutter: 3.75rem;
$custom-control-spacer-x: 1rem;
$custom-control-spacer-y: .625rem;

$custom-control-indicator-size: 1.25rem;
$custom-control-indicator-bg: $white;
$custom-control-indicator-bg-size: 70% 70%;

$custom-control-disabled-cursor: $cursor-disabled !default;
$custom-control-disabled-indicator-bg: $gray-lighter !default;
$custom-control-disabled-description-color: $gray-light !default;

$custom-control-checked-indicator-color: $body-color;
$custom-control-checked-indicator-bg: $white;
$custom-control-checked-indicator-box-shadow: none !default;

$custom-control-focus-indicator-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px $red;

$custom-control-active-indicator-color: $white !default;
$custom-control-active-indicator-bg: lighten($brand-primary, 35%) !default;
$custom-control-active-indicator-box-shadow: none !default;

$custom-checkbox-radius: 0;
$custom-checkbox-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-checked-indicator-color}' fill-rule='evenodd' d='M7.88377916,0.0941969052 C7.74755351,-0.0307405422 7.52409083,-0.0320556733 7.3854541,0.0941969052 L2.53503169,4.58372545 L0.492523609,3.82774429 C0.335803828,3.77207041 0.156507631,3.83026496 0.0665307504,3.96210685 C-0.0293642202,4.09822291 -0.0208158685,4.2814645 0.0871344698,4.41034734 L2.32822733,7.04280128 C2.39891563,7.12499696 2.50029031,7.17332803 2.60736389,7.17332803 L2.64626985,7.17157452 C2.74611022,7.1605055 2.83937492,7.1091058 2.8988846,7.03162266 L7.92268512,0.589562925 C8.03907422,0.440076365 8.02241589,0.222202992 7.88377916,0.0941969052 Z'/%3E%3C/svg%3E"), "#", "%23");

$custom-checkbox-indeterminate-bg: $brand-primary !default;
$custom-checkbox-indeterminate-indicator-color: $custom-control-checked-indicator-color !default;
$custom-checkbox-indeterminate-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indeterminate-indicator-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indeterminate-box-shadow: none !default;

$custom-radio-radius: 50% !default;
$custom-radio-checked-icon: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-checked-indicator-color}'/%3E%3C/svg%3E"), "#", "%23");

$custom-select-padding-x: 1.3rem;
$custom-select-padding-y: .375rem !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-light !default;
$custom-select-bg: $beige;
$custom-select-disabled-bg: $gray-lighter !default;
$custom-select-bg-size: 14px 8px; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;

$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3E%3Cpolyline fill='none' stroke='#{$custom-select-indicator-color}' stroke-width='2' points='13 1 7.061 6.068 1 1'/%3E%3C/svg%3E"), "#", "%23");

$custom-select-border-width: 0;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: 0;

$custom-select-focus-border-color: lighten($brand-primary, 25%) !default;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-sm-padding-y: .2rem !default;
$custom-select-sm-font-size: 75% !default;

$custom-file-height: 2.5rem !default;
$custom-file-width: 14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem $brand-primary !default;

$custom-file-padding-x: .5rem !default;
$custom-file-padding-y: 1rem !default;
$custom-file-line-height: 1.5 !default;
$custom-file-color: $gray !default;
$custom-file-bg: $white !default;
$custom-file-border-width: $border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $border-radius !default;
$custom-file-box-shadow: inset 0 .2rem .4rem rgba($black,.05) !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $gray-lighter !default;
$custom-file-text: (
  placeholder: (
    en: "Choose file..."
  ),
  button-label: (
    en: "Browse"
  )
) !default;