.hero {
  background-color: $oxford-blue;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 400px;
  z-index: 10;

  .blog-overview & {
    background-color: transparent;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      padding-top: 50px;
      height: 300px;
    }

    .hero__title {
      max-width: 350px;
      margin-top: 1rem;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }

  .booking & {
    height: 260px;
  }

  &__title {
    font-size: 50px;
    position: relative;
    color: $white;
    text-align: center;
    z-index: 5;

    .blog-overview & {
      color: $oxford-blue;
    }
  }

  &__bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .71;
    z-index: 1;
  }

  &--animate {
    overflow: hidden;

    .hero__bg {
      animation: animateRightToLeft 12s forwards ease-out;
      left: -100px;
      right: -100px;
    }
  }
}

@keyframes animateRightToLeft {
  from {
    transform: translateX(100px);
  }

  to {
    transform: translateX(-100px);
  }
}