.booking-bar {
  align-items: center;
  z-index: 20;

  @include media-breakpoint-up(sm) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .has-sticky-bar & {
    animation-name: slideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  &.fixed {
    @include media-breakpoint-up(sm) {
      position: relative;
      @include make-container;
      @include make-container-max-widths;
      margin-top: -80px;
    }
  }

  &__wrap {
    background-color: $yellow;
    padding: 20px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    font-size: 15px;
    line-height: (36 / 15);
    margin-bottom: 0;
  }

  &__summary {
    > span {
      margin-right: .5rem;
    }
  }
}

.reader-bar {
  background-color: $antique-white;
  height: 5px;
  overflow: hidden;

  @include media-breakpoint-down(xs) {
    display: none;
  }

  .bar {
    background-color: $red;
    height: 5px;
    transform: translateX(-100%);
    transition: transform ease .28s;
  }
}

.booking-panel {
  background-color: $yellow;
  margin-bottom: 30px;
  padding: 30px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
    transform: translateY(-60px);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}