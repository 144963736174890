.nav-home {
  &:hover {
    text-decoration: none;
  }
}

.nav-primary {
  @include font-roboto;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-left: 20px;
  }

  @include media-breakpoint-down(md) {
    background-color: $oxford-blue;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 240px;
    overflow: auto;
    transform: translateX(100%);
    transition: transform ease .28s;
    z-index: 99;

    &.is-visible {
      transform: translateX(0);
    }
  }

  ul {
    list-style: none;
    margin: 0;

    @include media-breakpoint-up(lg) {
      float: left;
      height: 50px;
    }
  }

  li {
    display: inline;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  a {
    font-size: 15px;
    display: inline-block;
    color: $white;
    padding: 14px;
  }
}

.nav-main {
  @include font-roboto;
  background-color: $red;
  padding-left: 15px;
  padding-right: 15px;
  color: $white;

  @include media-breakpoint-down(md) {
    padding-bottom: 15px;
    padding-top: 50px;
  }

  &-youtube--icon {
    margin-right: 5px;
    vertical-align: text-top;
  }

  a {
    display: block;
    padding: 10px 15px;

    @include media-breakpoint-up(lg) {
      display: inline-block;
      line-height: 50px;
      padding: 0 14px;
    }

    .no-touch & {
      &:hover span,
      &.is-active span {
        position: relative;

        &::after {
          left: 0;
          width: 100%;
        }
      }
    }
  }

  strong {
    display: block;
    padding: 14px;

    @include media-breakpoint-up(lg) {
      display: inline;
      padding: 0 14px;
    }
  }

  span {
    display: inline-block;
    position: relative;

    @include media-breakpoint-up(lg) {
      height: 50px;
    }

    .no-touch & {
      &::after {
        content: '';
        background-color: $white;
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0%;
        height: 3px;
        transition: width ease .28s, left ease .28s;
      }
    }
  }

  &__sub {
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    a {
      color: $oxford-blue;
      padding: 5px 10px 5px 15px;

      &.is-active {
        font-weight: bold;
      }
    }
  }
}

.nav-side {
  background-color: $oxford-blue;
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-down(md) {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  li {
    @include media-breakpoint-up(lg) {
      border-left: 1px solid rgba($white, .4);
    }

    &:first-child {
      border-left: 0;
    }
  }
}

.nav-secondary {
  position: relative;

  &__title {
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(#000, .2);
    }
  }

  &__btn-collapse {
    position: absolute;
    top: 0;
    right: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 0;
    }

    a {
      display: block;
      color: $body-color;
      padding: 4px 0;
      text-decoration: none;

      @include media-breakpoint-down(md) {
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }

      [class*="ico-"] {
        margin-bottom: -7px;
        margin-right: 5px;
      }

      &.is-active {
        font-weight: bold;

        @include media-breakpoint-down(md) {
          text-decoration: none;
        }
      }
    }
  }
}

.nav-footer {
  @include font-roboto;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    line-height: (27 / 15);
    margin: 0;
  }

  a {
    color: $body-color;
  }
}

.nav-backdrop {
  background-color: $modal-backdrop-bg;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;

  @include media-breakpoint-up(lg) {
    display: none !important;
  }

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: $modal-backdrop-opacity;
  }
}

.nav-blog {
  padding-left: 20px !important;

  @include media-breakpoint-up(lg) {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  svg {
    margin-right: 10px;
  }
}

.nav-blog-category {
  padding-top: 21px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-left: 70px;
    padding-right: 70px;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 2px solid $oxford-blue;

    &::after,
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0;
      height: 50px;
    }

    &::before {
      background-image: url('../images/elephant.svg');
      left: 15px;
      width: 64px;
    }

    &::after {
      background-image: url('../images/tree.svg');
      right: 15px;
      width: 70px;
    }

    strong,
    a {
      transform: translateY(50%);
    }
  }

  strong {
    display: block;
    margin-bottom: 1rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(md) {
    a {
      font-size: 14px;
      margin-bottom: .5rem;
      padding: 0.25rem 0.5rem;
    }
  }
}