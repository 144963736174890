@import "bootstrap/scss/button-group";

.btn-group {
  flex-wrap: wrap;

  .btn-primary.active {
    background-color: $oxford-blue;
    border-color: darken($oxford-blue, 12%);
  }

  &-wrap {
    display: inline-flex;
    flex-direction: column;
  }

  &-labels {
    display: flex;
    justify-content: space-between;
  }

  @include media-breakpoint-down(xs) {
    .btn {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-border-radius-sm);
    }
  }
}

.btn-group-spread {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}