.reviews {
  @include make-row;
  justify-content: center;

  &__item {
    @include make-col-ready;

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    > * {
      flex: 1;
    }
  }
}

.review {
  @extend .bl;
  @extend .bl--img-top;

  &__content {
    @extend .bl__content;
    align-items: center;
    border-radius: 2rem;
    text-align: center;
  }

  &__image {
    @extend .bl__img;
    flex-grow: 0;
    min-height: 260px;
    border-radius: 2rem 2rem 0 0;

    & + .review__content {
      border-radius: 0 0 2rem 2rem;
    }
  }

  &__score {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 1rem;
    width: 100%;
    margin-bottom: 1rem;

    img {
      width: 2rem;
      margin: 0;
    }
  }

  .stars {
    width: 7.5rem;
  }

  .star {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGcgaWQ9ImluZm8iLz48ZyBpZD0iaWNvbnMiPjxwYXRoIGQ9Ik0xMi45LDIuNmwyLjMsNWMwLjEsMC4zLDAuNCwwLjUsMC43LDAuNmw1LjIsMC44QzIyLDksMjIuMywxMCwyMS43LDEwLjZsLTMuOCwzLjljLTAuMiwwLjItMC4zLDAuNi0wLjMsMC45ICAgbDAuOSw1LjRjMC4xLDAuOC0wLjcsMS41LTEuNCwxLjFsLTQuNy0yLjZjLTAuMy0wLjItMC42LTAuMi0wLjksMGwtNC43LDIuNmMtMC43LDAuNC0xLjYtMC4yLTEuNC0xLjFsMC45LTUuNCAgIGMwLjEtMC4zLTAuMS0wLjctMC4zLTAuOWwtMy44LTMuOUMxLjcsMTAsMiw5LDIuOCw4LjlsNS4yLTAuOGMwLjMsMCwwLjYtMC4zLDAuNy0wLjZsMi4zLTVDMTEuNSwxLjgsMTIuNSwxLjgsMTIuOSwyLjZ6IiBpZD0iZmF2b3JpdGUiLz48L2c+PC9zdmc+');
    background-size: 1.5rem;
    display: block;
    width: 100%;
    height: 1.5rem;
    margin-left: 0;
  }
}