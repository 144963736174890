.section {
  @include clearfix;
  margin-top: 60px;
  margin-bottom: 60px;

  &.trips {
    margin-bottom: 0;
  }

  &--cta {
    background-color: $antique-white;
    margin-bottom: 0;
    padding: 100px 15px;
    text-align: center;
  }
}