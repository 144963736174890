.blog {
  position: relative;
  margin-top: 0 !important;
  transform: translateY(-60px);
  z-index: 10;

  .content {
    background-color: $white;
    padding: 30px;

    @include media-breakpoint-up(lg) {
      padding: 80px 120px;
    }
  }

  &__header {
    margin-bottom: 2rem;

    h1 {
      @include type-h2;
    }
  }

  &__footer {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding-top: .5rem;
    border-top: 1px solid $oxford-blue;

    a {
      @include font-roboto;
      color: $oxford-blue;
    }
  }

  &__back-link {
    background-color: $red;
    display: inline-block;
    margin-bottom: 2rem;
    padding: 10px 15px;
    color: $white;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: 0;
      left: 15px;
    }

    &:hover {
      color: $white;
    }
  }
}