@import "bootstrap/scss/list-group";

.list-group {
  margin-bottom: 30px;

  &--included {
    .list-group-item {
      border-color: $xanadu;
    }
  }

  &--not-included {
    .list-group-item {
      border-color: $flame;
    }
  }

  &-item {
    margin-bottom: 10px;

    img {
      margin-right: .6rem;
    }
  }
}