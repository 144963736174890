.guide {
  position: relative;
  margin-top: 0 !important;
  transform: translateY(-30px);
  z-index: 10;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  &__nav {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      @include make-col-ready;
      @include make-col(4);
      align-self: flex-start;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }

    &::before {
      content: '';
      background-color: $white;
      display: none;
      position: absolute;
      top: 0;
      left: -2000px;
      right: 15px;
      bottom: 0;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .nav-secondary {
      background-color: $white;
      padding: 30px;

      @include media-breakpoint-down(md) {
        padding: 15px 30px;
      }

      @include media-breakpoint-up(lg) {
        padding: 40px 30px;
      }

      &__btn-collapse {
        top: 15px;
        right: 30px;
      }
    }

    #guide-nav-collapse {
      @include media-breakpoint-up(lg) {
        display: block !important;
      }
    }
  }

  &__content {
    @include media-breakpoint-up(lg) {
      @include make-col-ready;
      @include make-col(8);
    }

    .content {
      background-color: $white;
      padding: 30px;

      @include media-breakpoint-up(lg) {
        padding: 40px;
      }
    }
  }

  &__pager {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    a {
      display: flex;
      align-items: center;

      &.prev {
        i {
          margin-left: 0;
          margin-right: 10px;
          transform: rotate(180deg);
        }
      }

      i {
        background-image: url('../images/slide-next.svg');
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: relative;
        width: 8px;
        height: 16px;
        margin-left: 10px;
      }
    }
  }

  .figure--content {
    display: inline-block;

    img {
      line-height: 1;
      margin-bottom: 0rem;
    }

    figcaption {
      font-size: 90%;
      background-color: #101336;
      padding: 0.3rem;
      color: $white;
    }
  }
}