$oxford-blue: #101236;
$midnight-green: #044d5d;
$xanadu: #6a9673;
$flame: #df4f22;
$carrot-orange: #ee9a1f;
$jonquil: #ffd06b;
$old-lace: #faf4e4;
$antique-white: #f9f0d8;
$white: #fff;
$beige: #f1efe9;

$green: $xanadu;
$green-dark: $midnight-green;
$red: $flame;
$orange: $carrot-orange;
$yellow: $jonquil;
$beige: $old-lace;
$blue-dark: $oxford-blue;

$body-bg: #fbe8bc;
$body-color: $oxford-blue;

$brand-primary: $red;