@import "bootstrap/scss/buttons";

.btn {
  @include font-roboto;
  cursor: pointer;

  &.collapsed {
    .is-shown {
      display: inline;
    }

    .is-hidden {
      display: none;
    }
  }

  .is-shown {
    display: none;
  }

  .is-hidden {
    display: inline;
  }
}

.btn-country {
  font-weight: 500;
  background-color: transparent;
  border-radius: 0;
  color: $white;

  @include media-breakpoint-down(md) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.dropdown-toggle::after {
    background-image: url('../images/down.svg');
    width: 14px;
    height: 8px;
    margin-left: 21px;
    border: 0;
  }
}

.btn-menu {
  background-color: $oxford-blue;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 50px;
  color: $white;
  z-index: 1060;

  @include media-breakpoint-down(md) {
    display: flex;
  }

  @include media-breakpoint-down(xs) {
    position: fixed;
  }

  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }

  &__text {
    background-color: $red;
    padding: 14px 18px;
    text-decoration: none;

    @include media-breakpoint-down(xs) {
      padding: 14px;
    }
  }

  &__icon {
    padding: 18px;

    i {
      background-color: $white;
      display: block;
      width: 16px;
      height: 2px;
      transform: translateY(0);
      transform-origin: 0% 0%;
      transition: transform ease .28s;

      &:nth-child(2) {
        transform: translateY(4px);
      }

      &:nth-child(3) {
        transform: translateY(8px);
        transform-origin: 100% 100%;
      }
    }
  }

  &.is-active {
    .btn-menu__icon {
      i {
        margin-top: 0;
        transform: rotate(45deg) translateY(0);

        &:nth-child(2) {
          transform: translateX(500%);
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translateY(-6.5px);
        }
      }
    }
  }
}

.btn-price {
  background-color: transparent;
  border: 0;

  &:focus,
  &:hover {
    text-decoration: none;

    .btn-price__text {
      z-index: 0;
    }

    .btn-primary {
      @extend .btn-primary:hover;
    }
  }

  .btn + .btn {
    margin-left: 0 !important;
  }

  &__text {
    font-weight: normal;
    background-color: $oxford-blue;
    border-right: 0;
    color: $white;
  }
}