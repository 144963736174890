.usp {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    &::before {
      content: '';
      background-image: url('../images/check.svg');
      background-repeat: no-repeat;
      display: inline-block;
      width: 14px;
      height: 13px;
      margin-right: 8px;
    }
  }

  &--bar {
    background-color: $yellow;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    li {
      flex: 1;
      padding: 14px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        flex: 0 0 50%;
        text-align: left;
      }
    }
  }

  &--list {
    li {
      position: relative;
      padding: 6px 0;
      padding-left: 25px;

      &::before {
        position: absolute;
        top: .6rem;
        left: 0;
      }
    }
  }
}