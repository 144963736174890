.header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 20;

  @include media-breakpoint-down(md) {
    z-index: 1050;
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}