@import "row";

.bl {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  &__content {
    background-color: $white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;

    p {
      flex-grow: 1;
      max-width:100%;
    }

    .price {
      @include font-roboto;
      align-self: flex-end;
      font-size: 16px;
    }

    time {
      margin-bottom: 1rem;
    }
  }

  &__img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-left: 0 !important;
    padding-right: 0 !important;

    img {
      @include img-fluid;
    }
  }

  &--img-right,
  &--img-left {
    @include media-breakpoint-up(lg) {
      min-height: 360px;
    }

    .bl__content {
      padding: 30px !important;
      @include media-breakpoint-up(lg) {
        padding: 30px 50px !important;
      }
    }

    .bl__img {
      @include media-breakpoint-down(md) {
        height: 280px;
      }
    }
  }

  &--img-left {
    .bl__content,
    .bl__img {
      @include make-col-ready;

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    .bl__img {
      @include media-breakpoint-up(lg) {
        @include make-col-pull(6);
      }
    }

    .bl__content {
      @include media-breakpoint-up(lg) {
        @include make-col-push(6);
      }
    }
  }

  &--img-right {
    .bl__content,
    .bl__img {
      @include make-col-ready;

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }
  }

  &--img-bottom {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      min-height: 520px !important;
    }

    .bl__img,
    .bl__content {
      @include make-col-ready;
      flex: 1;
    }

    .bl__img {
      flex-grow: 0;
      min-height: 260px;
    }
  }

  &--img-top {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      min-height: 456px !important;
    }

    h3 {
      flex-grow: 1;
    }

    .bl__img {
      flex-grow: 0;
      min-height: 260px;
    }
  }

  &--content-only {
    .bl__content {
      @include media-breakpoint-up(md) {
        padding: 70px;
      }
    }
  }

  &--photo-slider {
    .bl__slider {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .bl__content,
    .bl__slider {
      @include make-col-ready;

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
    }

    .bl__content {
      padding: 30px !important;

      @include media-breakpoint-up(lg) {
        padding: 30px 50px !important;
      }
    }

    .slick-list,
    .slick-track {
      height: 100%;
    }

    &--left {
      .bl__slider {
        @include media-breakpoint-up(lg) {
          @include make-col-pull(6);
        }
      }

      .bl__content {
        @include media-breakpoint-up(lg) {
          @include make-col-push(6);
        }
      }
    }
  }
}