.arrow {
  font-family: 'Kalam', cursive;
  font-size: 30px;
  position: relative;
  width: fit-content;
  max-width: 368px;
  margin: 40px auto;
  padding-right: 38px;
  text-align: center;
  transform: rotate(-6deg);

  &::after {
    content: '';
    background: url('../images/arrow.svg') no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 31px;
    height: 75px;
    transform: translateY(-40%);
  }
}