.share {
  @include font-roboto;
  display: flex;
  align-items: center;

  .social {
    img {
      width: 20px;
      height: 20px;
    }
  }
}