$dropdown-min-width: 10rem !default;
$dropdown-padding-y: .5rem !default;
$dropdown-margin-top: .125rem !default;
$dropdown-bg: $oxford-blue;
$dropdown-border-color: rgba($black,.15) !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: rgba($white, .4);
$dropdown-box-shadow: none;

$dropdown-link-color: $white;
$dropdown-link-hover-color: darken($white, 5%);
$dropdown-link-hover-bg: $midnight-green;

$dropdown-link-active-color: $white;
$dropdown-link-active-bg: inherit;

$dropdown-link-disabled-color: $gray-light !default;

$dropdown-item-padding-x: 0.75rem;

$dropdown-header-color: $gray-light !default;