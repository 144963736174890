[class*='ico-'] {
  display: inline-block;
  width: 28px;
  height: 28px;
}

.ico-acaia { background-image: url('../icons/acaia.svg'); }
.ico-airport-transfer { background-image: url('../icons/airport-transfer.svg'); }
.ico-baobab { background-image: url('../icons/baobab.svg'); }
.ico-beach { background-image: url('../icons/beach.svg'); }
.ico-bird { background-image: url('../icons/bird.svg'); }
.ico-camping { background-image: url('../icons/camping.svg'); }
.ico-chimp { background-image: url('../icons/chimp.svg'); }
.ico-coolbox { background-image: url('../icons/coolbox.svg'); }
.ico-culture { background-image: url('../icons/culture.svg'); }
.ico-currency { background-image: url('../icons/currency.svg'); }
.ico-elephant { background-image: url('../icons/elephant.svg'); }
.ico-exclude-area { background-image: url('../icons/exclude-area.svg'); }
.ico-fridge { background-image: url('../icons/fridge.svg'); }
.ico-fuel { background-image: url('../icons/fuel.svg'); }
.ico-gorilla { background-image: url('../icons/gorilla.svg'); }
.ico-gsm-local { background-image: url('../icons/gsm-local.svg'); }
.ico-guide { background-image: url('../icons/guide.svg'); }
.ico-heart { background-image: url('../icons/heart.svg'); }
.ico-hiking { background-image: url('../icons/hiking.svg'); }
.ico-insurance { background-image: url('../icons/insurance.svg'); }
.ico-leaf { background-image: url('../icons/leaf.svg'); }
.ico-meals { background-image: url('../icons/meals.svg'); }
.ico-mileage { background-image: url('../icons/mileage.svg'); }
.ico-park { background-image: url('../icons/park.svg'); }
.ico-rafting { background-image: url('../icons/rafting.svg'); }
.ico-rhino { background-image: url('../icons/rhino.svg'); }
.ico-road-assistance { background-image: url('../icons/road-assistance.svg'); }
.ico-road-map { background-image: url('../icons/road-map.svg'); }
.ico-road-sign { background-image: url('../icons/road-sign.svg'); }
.ico-sun { background-image: url('../icons/sun.svg'); }
.ico-tent { background-image: url('../icons/tent.svg'); }
.ico-things-to-do { background-image: url('../icons/things-to-do.svg'); }
.ico-tickets { background-image: url('../icons/tickets.svg'); }
.ico-transport { background-image: url('../icons/transport.svg'); }
.ico-tshirt { background-image: url('../icons/tshirt.svg'); }