@import "bootstrap/scss/card";

.card {
  &--with-table {
    margin-bottom: 30px;
    border: 0;

    .card-block {
      border: 2px solid $body-color;
      border-bottom: 0;
    }

    .table {
      margin-bottom: 0;
    }
  }
}