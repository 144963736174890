.photo-slider {
  position: relative;
  margin-bottom: calc(40px + 2rem);

  &__item {
    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 0;

    img {
      position: relative;
      margin-bottom: 0;
      z-index: 2;
    }

    figcaption {
      background-color: rgba($oxford-blue, .6);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px;
      color: $white;
      z-index: 3;
    }
  }

  &--bg-image {
    height: 100%;
    min-height: 250px;
  }

  .slick-arrow {
    @include text-hide;
    background-color: $flame;
    position: absolute;
    top: 50%;
    bottom: 0;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    border: 0;
    color: $white;
    z-index: 4;

    i {
      background-image: url('../images/slide-next.svg');
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      position: relative;
      width: 8px;
      height: 16px;
      margin: 0 10px;
    }

    &.slick-disabled {
      @include text-hide;
      background: none;
      cursor: default;
      pointer-events: none;

      i {
        display: none;
      }
    }

    &.slick-prev {
      background-position: 10% 50%;
      left: 0;
      text-align: left;

      i {
        transform: rotate(180deg);
      }
    }

    &.slick-next {
      background-position: 90% 50%;
      right: 0;
      text-align: right;
    }
  }
}