@import "bootstrap/scss/dropdown";

.dropdown-menu {
  border-radius: 0;
}

.btn-country {
  + .dropdown-menu {
    top: calc(100% + 14px);
    min-width: (155 / 16) + rem;
    margin: 0;

    @include media-breakpoint-down(md) {
      position: relative;
    }

    .dropdown-item {
      padding: 4px 0 4px 18px;

      @include media-breakpoint-down(md) {
        padding-left: 15px;
      }
    }

    .dropdown-divider {
      margin-left: 18px;
      margin-right: 18px;
    }
  }
}

.dropdown-country {
  .dropdown-header {
    display: none;
    padding-left: 15px;
    color: $white;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    .dropdown-toggle {
      display: none !important;
    }

    .dropdown-menu {
      display: block !important;
    }
  }
}