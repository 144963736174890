$input-padding-x: 1.25rem;
$input-padding-y: 1.3rem;
$input-line-height: 1.25 !default;

$input-bg: rgba($beige, .7);
$input-bg-disabled: rgba($gray-lighter, .5);

$input-color: $gray !default;
$input-border-color: rgba(0,0,0,.15) !default;
$input-box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !default;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$input-bg-focus: $antique-white;
$input-border-focus: #66afe9 !default;
$input-box-shadow-focus: $input-box-shadow, 0 0 8px rgba($input-border-focus,.6) !default;
$input-color-focus: $input-color !default;

$input-color-placeholder: #999 !default;

$input-padding-x-sm: .5rem !default;
$input-padding-y-sm: .25rem !default;

$input-padding-x-lg: 1.5rem !default;
$input-padding-y-lg: .75rem !default;

$input-height: 3.75rem;
$input-height-lg: (($font-size-lg * $line-height-lg) + ($input-padding-y-lg * 2)) !default;
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;