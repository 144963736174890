.trip {
  &-summary {
    background-color: $white;
    position: relative;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    min-height: 320px;
    outline: 20px solid $oxford-blue;
    transform: translateY(-40px);
    z-index: 20;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    &__txt,
    &__map {
      @include media-breakpoint-up(md) {
        flex: 1;
      }
    }

    &__txt {
      display: flex;
      flex-direction: column;
      padding: 30px;

      p {
        flex-grow: 1;
      }

      &__order {
        @include media-breakpoint-down(xl) {
          span {
            display: block;
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    &__map {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      @include media-breakpoint-down(lg) {
        min-height: 160px;
      }

      a {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:hover {
          text-decoration: none;

          span {
            @extend .btn-primary:hover;
          }
        }

        span {
          margin-bottom: 15px;
          margin-right: 15px;
          text-decoration: none;
        }
      }
    }
  }
}