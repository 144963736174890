@import "bootstrap/scss/reboot";

h2 { @include type-h2; }
h3 { @include type-h3; }
h4 { @include type-h4; }

a {
  &:focus {
    color: inherit;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .875rem;
}